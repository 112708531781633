<template>
  <div class="access-menu">
    <div class="title">
      <span class="s">权限菜单</span>
    </div>
    <div class="search-cell">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <!-- <el-form-item label="菜单名称：">
          <el-input
            v-model="formInline.user"
            size="mini"
            placeholder="菜单名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="类型：">
          <el-select size="mini" v-model="formInline.region" placeholder="全部">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <!-- <el-button size="mini" type="primary" @click="onSubmit"
            >查询</el-button
          > -->
          <el-button size="mini" type="success" @click="addAccess"
            >新增</el-button
          >
          <!-- <el-button size="mini" plain>重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <div class="tab">
      <el-table
        size="small "
        v-loading="loading"
        border
        row-key="id"
        :data="tableData"
        style="width: 100%; text-algin: center"
        :header-cell-style="{ background: '#F2F2F2', 'border-color': '#000' }"
        :row-style="{ 'border-color': '#000' }"
        :cell-style="{ 'border-color': '#000' }"
        :header-row-style="{ 'border-color': '#000' }"
      >
        <!-- <el-table-column
          show-overflow-tooltip
          align="center"
          prop="id"
          label="序号"
          width="100"
        >
        </el-table-column> -->
        <el-table-column
          show-overflow-tooltip
          align="left"
          prop="node_name"
          label="菜单名称"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="is_menu"
          label="类型"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.is_menu == 1 ? "菜单" : "按钮" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="control_name"
          label="组织标识"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="sort"
          label="排序"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="time"
          label="创建时间"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.time | formatTimeS("all") }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="address"
          label="操作"
        >
          <template slot-scope="scope">
            <span class="btn" @click="handleEdit(scope.row)">编辑</span
            ><span class="btn" @click="handleDel(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { nodeList, nodeDel } from "@/api/admin.js";
export default {
  data() {
    return {
      formInline: {
        user: "",
      },
      tableData: [],
      loading: true,
    };
  },
  created() {
    this.getNodeList();
  },
  methods: {
    getNodeList() {
      this.loading = true;
      nodeList().then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.tableData = res.data;
        }
      });
    },
    handleEdit(val) {

      this.$router.options.routes.forEach((item) => {
        if (item.name == "admins") {
          item.children.forEach((val) => {
            if (val.name == "addAccess") {
   
              val.meta.bread[val.meta.bread.length - 1].name = "编辑";
            }
          });
        }
      });
      sessionStorage.ADMIN_NODE_INFO = JSON.stringify(val);
      this.$router.push("addAccess");
    },
    addAccess() {
      this.$router.options.routes.forEach((item) => {
        if (item.name == "admins") {
          item.children.forEach((val) => {
            if (val.name == "addAccess") {

              val.meta.bread[val.meta.bread.length - 1].name = "创建";
            }
          });
        }
      });
      sessionStorage.removeItem("ADMIN_NODE_INFO");
      this.$router.push("addAccess");
    },
    handleDel(val) {
      this.$confirm("确定删除该菜单吗?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        nodeDel({
          id: val.id,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success("删除成功");
          }
          this.getNodeList();
        });
      });
    },
  },
};
</script>
<style lang="less">
.access-menu {
  background: #fff;
  padding: 20px;
  .title {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    .s {
      font-size: 14px;
      color: #ee4d2d;
      border-bottom: 2px solid #ee4d2d;
      padding: 0 10px;
      padding-bottom: 10px;
    }
  }
  .search-cell {
    padding: 10px 0 0;
  }
  .tab {
    .btn {
      font-size: 12px;
      cursor: pointer;
      margin: 0 10px;
    }
    .btn:hover {
      border-bottom: 1px solid #000;
    }
  }
}
.el-form-item__label {
  font-size: 12px;
}
.el-table--border .el-table__cell {
  border-color: #000;
}
</style>